<template>
  <b-row style="justify-content: center;width:100%;" class="p-0 m-0">

    <div class="gameLeftFrame">
      <b-col class="p-0 m-0 col-12">
      <vue-perfect-scrollbar 
            ref="Prematch"
            :settings="perfectScrollbarSettings"
            class="upcoming-scroll-area"
            :always-show-scrollbar="true"
            v-bind:style="{ height: scroll_height + 'px'}"
            @ps-scroll-y="onScroll"
          >
        <GamePrematch/>
      </vue-perfect-scrollbar>
      </b-col>
    </div>

    <div class="cartFrame">
      <b-col class="px-0 m-0 d-none d-xl-block game-cart-frame" style="width:320px;">
        <GameCart2/>
      </b-col>
    </div>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
      class="d-block d-xl-none"
    />
  </b-row>

</template>

<script>
import GamePrematch from '../games2/GameLeft2.vue'
import GameSpecial from '../games2/GameLeft3.vue'
import GameCart2 from '../games2/GameCart2.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BRow, BCol, BAlert} from 'bootstrap-vue'
import EventBus from '../../event-bus';
import useJwt from '@/auth/jwt/useJwt'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  data(){
    return {
      //timer : null,
      scroll_height : document.documentElement.clientHeight - 10,
      client_width : document.documentElement.clientWidth,
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  components: {
    BRow, BCol, BAlert,
    GamePrematch, GameSpecial, GameCart2,
    EventBus, VuePerfectScrollbar, AppCustomizer
  },
  created(){
    EventBus.$on('upcoming-scroll-top', ()=> {
      let container = document.querySelector('.upcoming-scroll-area');
      container.scrollTop = 0;
      this.$forceUpdate();
    });
    EventBus.$on('toggle_view', (obj)=> {
      if( this.view_mode == 'prematch' ){
        this.view_mode = 'odds';
      }else{
        this.view_mode = 'prematch';
      }
      EventBus.$emit('game_view', obj);
    });
    EventBus.$on('special_mode', ()=>
    {
      this.mode = 'special';
    });
    EventBus.$on('prematch_mode', ()=>
    {
      this.mode = 'prematch';
    });
  },
  setup(){
    const perfectScrollbarSettings = {
      suppressScrollX : true,
      scrollingThreshold: 100000,
      wheelSpeed:1,
      swipeEasing:true,
    }
    return{
      perfectScrollbarSettings,
    }
  },
  updated() {
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    setInterval(this.checkAuth, 90 * 1000 );
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
  },
  methods: {
    onResize() {
      this.scroll_height = document.documentElement.clientHeight - 90;
      this.client_width =  document.documentElement.clientWidth;
    },

    checkAuth(){
      const at = localStorage.getItem("accessToken");
      const rt = localStorage.getItem("refreshToken");

      this.$api('http://server.honey-game.com/auth/account', 'post', {
        'at' : at,
        'rt' : rt
      }).then((res)=>{
        if(res.result == 1 ) {
          // CART에 보유금액 업데이트
          EventBus.$emit('update_user', res.userData);
          useJwt.setToken(res.access_token);
          useJwt.setRefreshToken(res.refresh_token);
          localStorage.setItem('userData', JSON.stringify(res.userData));
        } else {
          this.$router.replace('/pages/miscellaneous/not-authorized');
        }
      });
    },

    onScroll(event) {
      if( this.$refs.Prematch.ps.scrollbarYTop + this.$refs.Prematch.ps.scrollbarYHeight > this.$refs.Prematch.ps.containerHeight - 100) 
      {
        EventBus.$emit('next_page');
      }
    }
  }
}
</script>

<style>
.upcoming-scroll-area {width:100%;}
.detail-scroll-area {width:100%;}

.gameLeftFrame {width:calc(100%-340px) !important; float:left; }

@media (max-width: 1200px) {
  .gameLeftFrame {
    width:100% !important;
  }
}

.cartFrame {width:320px !important;float:left;}
</style>
